<i18n>
{
	"en": {
		"noItemsMessage": "No courses to show."
	},
	"fi": {
		"noItemsMessage": "Kursseja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<FilterableList
			v-if="items.length"
			:items="items"
			subtitleSrc="startDate"
			summarySrc="location"
			:enableClick="false"
		>
			<template v-slot:content="{ item }">
				<v-list-item-title
					v-if="item.title"
					class="v-list-item__title--multiline"
				>
					{{ item.title }}
					{{ item.startDate }}
					<span
						v-if="item.endDate && item.endDate !== item.startDate"
					>
						- {{ item.endDate }}
					</span>
				</v-list-item-title>
				<v-list-item-subtitle
					v-if="item.location"
					class="v-list-item__title--multiline"
					v-html="item.location"
				/>
				<v-list-item-subtitle
					v-if="item.status"
					class="v-list-item__title--multiline"
					v-html="item.status.title"
				/>
			</template>
		</FilterableList>
		<v-alert
			v-else
			type="info"
			class="ma-3"
		>
			{{ $i18n.t('noItemsMessage') }}
		</v-alert>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import FilterableList from '../components/FilterableList.vue'

export default {
	name: 'CourseContainer',
	components: {
		FilterableList
	},
	data () {
		return {
			searchQuery: '',
		}
	},
	computed: {
		...mapState({
			items: state => state.courses,
		}),
	},
	mounted () {
		this.$api.Courses.doRequest()
	},
};
</script>
