<template>
  <div>
    <!-- Output child page -->
    <router-view />

    <!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'benefitContainer'">
			<FilterableList
				v-if="items.length"
				:items="items"
				@itemClick="itemClick"
			/>
			<v-alert
				v-else
				type="info"
				class="ma-3"
			>
				Ei jäsenetuja
			</v-alert>
		</template>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'BenefitContainer',
	components: {
		FilterableList,
	},
  computed: {
    ...mapState({
      items: state => state.benefits,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'benefit', params: { pagename: item.name } })
		}
	},
	mounted () {
    this.$api.Benefits.doRequest()
  },
};
</script>
