<i18n>
{
	"en": {
		"fields": {
			"locale": {
				"label": "Language"
			},
			"darkMode": {
				"label": "Dark theme"
			}
		},
		"localeSwitch": {
			"warn": "When you change application language, all data will be reloaded. Please confirm language change by clicking the button below.",
			"buttonText": "Change language"
		}
	},
	"fi": {
		"fields": {
			"locale": {
				"label": "Kieli"
			},
			"darkMode": {
				"label": "Tumma väriteema"
			}
		},
		"localeSwitch": {
			"warn": "Sovelluksen kielen vaihtamisen yhteydessä tiedot tulee ladata uudelleen. Vahvista kielen vaihto alla olevalla painikkeella.",
			"buttonText": "Vaihda kieli"
		}
	}
}
</i18n>

<template>
	<v-container>
		<v-card v-if="ready">
			<v-card-text>
				<!-- <v-radio-group
					v-if="config.languages && config.languages.length > 1"
					v-model="locale"
				>
					<template v-slot:label>
						<p class="font-weight-bold mb-0">
							{{ $t('fields.locale.label') }}
						</p>
					</template>
					<v-radio
						v-for="(item, i) in config.languages"
						:key="i"
						:label="item.title"
						:value="item.code"
					></v-radio>
				</v-radio-group> -->

				<!--
					Show notification when user is about to change locale.
					Notification will be shown in the selected language.
				-->
				<v-expand-transition>
					<div
						v-show="locale !== settings.locale"
						class="mb-6"
					>
						<v-alert
							type="info"
							dense
						>
							{{$i18n.t('localeSwitch.warn', locale)}}
						</v-alert>
						<v-btn
							@click="changeLocale"
							color="success"
						>
							{{$i18n.t('localeSwitch.buttonText', locale)}}
						</v-btn>
					</div>
				</v-expand-transition>

				<v-switch
					v-model="settings.darkMode"
					:label="$t('fields.darkMode.label')"
				/>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Settings',
	data: () => ({
		ready: false,
		settings: {},
		locale: null,
	}),
	computed: {
		...mapState([
			'config',
		]),
	},
	methods: {
		// This method will run when user confirms locale change.
		changeLocale () {
			// Update current locale
			this.settings.locale = this.locale

			// Raise event to root component. App.vue will handle data reloading.
			this.$nextTick(() => {
				this.$emit('localeChanged')
			})
		}
	},
	mounted () {
		// Load settings from store
		this.settings = this.$store.state.settings

		// Initially set locale to match current locale
		this.locale = this.settings.locale

		this.ready = true
	},
	watch: {
		settings: {
			deep: true,
			handler (val) {
				this.$store.dispatch('setSettings', val)
			}
		}
	}
};
</script>
