import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './i18n'
import router from './router'
import store from './store'
import api from './plugins/api'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// Global components
import Spinner from '@/components/Spinner'
import SchemaToForm from '@/components/SchemaToForm'

Vue.component('Spinner', Spinner)
Vue.component('SchemaToForm', SchemaToForm)

Vue.use(api)
Vue.config.productionTip = false

new Vue({
	i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
