<i18n>
{
	"en": {
		"applicationLabel": "Application form",
		"reservationsLabel": "My reservations",
		"infoLabel": "Information about resorts"
	},
	"fi": {
		"applicationLabel": "Lomapaikkahakemus",
		"reservationsLabel": "Vahvistetut varaukset",
		"infoLabel": "Lisätietoja lomapaikoista"
	}
}
</i18n>

<template>
	<div>
		<!-- Output child views -->
		<router-view />

		<v-container
			v-if="$route.name == 'resorts'"
			class="pt-6"
		>
			<v-alert
				v-if="data.info_text"
				type="info"
				border="top"
				colored-border
				elevation="2"
				class="multi-line mb-6"
			>
				<span v-html="data.info_text"></span>
			</v-alert>
			<v-btn
				block
				large
				color="primary"
				class="mb-5"
				:to="({ name: 'resortsForm' })"
			>
				<v-icon left>mdi-send</v-icon>
				{{$i18n.t('applicationLabel')}}
			</v-btn>
			<v-btn
				block
				large
				color="primary"
				class="mb-5"
				:to="({ name: 'resortReservations' })"
			>
				<v-icon left>mdi-checkbox-marked-outline</v-icon>
				{{$i18n.t('reservationsLabel')}}
			</v-btn>
			<v-btn
				block
				large
				color="primary"
				class="mb-5"
				:to="data.info_link && data.info_link.match(/^https?\:\/\//) ? null : data.info_link"
				:href="data.info_link && data.info_link.match(/^https?\:\/\//) ? data.info_link : null"
			>
				<v-icon left>mdi-information-outline</v-icon>
				{{$i18n.t('infoLabel')}}
			</v-btn>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Resorts',
	computed: {
		...mapState({
			data: state => state.resortsData,
			user: state => state.user,
		}),
	},
	mounted () {
		// @todo remove this when resorts feature is public
		if (!this.user._show_resorts) this.$router.push('/error404')
		this.$api.Resorts.doRequest()
	},
};
</script>
