export default {
	general: {
		save: 'Save',
		cancel: 'Cancel',
		close: 'Close',
		yes: 'Yes',
		no: 'No',
		website: 'Website',
	},
	routes: {
		error404: {
			title: 'Page not found',
		},
		membershipCard: {
			title: 'Card',
		},
		myAccount: {
			title: 'My account',
			headline: 'My account',
		},
		benefitContainer: {
			title: 'Benefits',
			headline: 'Benefits',
		},
		benefit: {
			title: 'Benefit',
			headline: 'Benefit',
		},
		eventContainer: {
			title: 'Events',
			headline: 'Events',
		},
		event: {
			title: 'Event',
			headline: 'Event',
		},
		newsContainer: {
			title: 'News',
			headline: 'News',
		},
		news: {
			title: 'News article',
			headline: 'News article',
		},
		courseContainer: {
			title: 'Courses',
			headline: 'Course history',
		},
		resorts: {
			title: 'Resorts',
			headline: 'Resorts',
		},
		resortsForm: {
			title: 'Resorts application',
			headline: 'Resorts application',
		},
		resortApplications: {
			title: 'Applications',
			headline: 'Applications',
		},
		resortReservations: {
			title: 'Reservations',
			headline: 'Reservations',
		},
		pageContainer: {
			title: 'AKT',
			headline: 'AKT',
		},
		page: {
			title: 'Page',
			headline: 'Page',
		},
		settings: {
			title: 'Settings',
			headline: 'Settings',
		},
	},
}