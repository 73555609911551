<i18n>
{
  "en": {
		"updateYourDetails": "Update your details in eAsiointi",
		"regionDetails": {
			"titlePrefix": "Your regional office"
		},
		"departmentDetails": {
			"titlePrefix": "Your department",
			"chairman": "Chairman",
			"secretary": "Secretary",
			"financialManager": "Financial manager",
			"officeAddress": "Office",
			"telephone": "Telephone"
		},
		"collectiveAgreement": {
			"title": "Collective agreement and wage chart",
			"info": "Jos sopimusala tai työehtosopimus ei ole jäsenkortissasi oikein ota yhteyttä jäsenrekisteriin soittamalla p.",
			"or": "tai",
			"infoLinkText": "täytä yhteydenottolomake tästä"
		},
		"payRaise": {
			"title": "Pay raise"
		},
		"dailyAllowance": {
			"title": "Daily allowance"
		}
	},
  "fi": {
		"updateYourDetails": "Päivitä tietojasi eAsioinnissa",
		"regionDetails": {
			"titlePrefix": "Aluetoimistosi"
		},
		"departmentDetails": {
			"chairman": "Puheenjohtaja",
			"secretary": "Sihteeri",
			"financialManager": "Taloudenhoitaja",
			"officeAddress": "Toimiston osoite",
			"telephone": "Puhelin"
		},
		"collectiveAgreement": {
			"title": "Työehtosopimus ja palkkataulukko",
			"info": "Jos sopimusala tai työehtosopimus ei ole jäsenkortissasi oikein ota yhteyttä jäsenrekisteriin soittamalla p.",
			"or": "tai",
			"infoLinkText": "täytä yhteydenottolomake tästä"
		},
		"payRaise": {
			"title": "Palkankorotus"
		},
		"dailyAllowance": {
			"title": "Päivärahat"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container>
			<v-card>
				<v-img
					v-if="user.hero_image"
					class="white--text align-end"
					aspect-ratio="1.628"
					:src="user.hero_image.url"
					dark
				>
					<v-card-title class="headline black--text">
						{{user.firstname}} {{user.lastname}}
					</v-card-title>
					<v-card-subtitle v-if="user.membership" class="black--text">
						{{user.membership}}
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{user.firstname}} {{user.lastname}}
					</v-card-title>
					<v-card-subtitle v-if="user.membership">
						{{user.membership}}
					</v-card-subtitle>
				</template>
				<v-card-text v-if="user._details">
					<FilterableList
						:items="user._details"
						subtitleSrc="label"
						titleSrc="value"
						:enableClick="(false)"
						:enableSearch="(false)"
					/>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn
						text
						block
						color="primary"
						href="https://tunnistus.avoine.fi/sso-login/?service=akt-lotta&return=https%3A%2F%2Feasiointi.akt.fi%2Fauth"
						target="_blank"
					>
						<v-icon left>mdi-pencil</v-icon>
						{{$t('updateYourDetails')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<v-container v-if="user._show_collective_agreement_info === true">
			<v-alert
				type="info"
				border="top"
				colored-border
				elevation="2"
			>
				{{$t('collectiveAgreement.info')}}
				<a href="tel:+358961311244">09 6131 1244</a>
				{{$t('collectiveAgreement.or')}}
				<a href="https://www.akt.fi/jasenyys/jasenrekisteripalvelut/sopimusalan-muutos/" target="_blank">{{$t('collectiveAgreement.infoLinkText')}}</a>.
			</v-alert>
		</v-container>

		<!-- Panels -->
		<v-container>
			<v-expansion-panels
				:multiple="(true)"
			>
				<!-- Region contact details -->
				<v-expansion-panel
					v-if="(user._region_contact_details && Object.keys(user._region_contact_details).length)"
					color="primary"
				>
					<v-expansion-panel-header color="primary white--text">
						{{$t('regionDetails.titlePrefix')}}:
						{{user._region_contact_details.title}}

						<template v-slot:actions>
							<v-icon color="white">
								mdi-chevron-down
							</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content
						class="pt-6"
					>
						<RichText
							:content="user._region_contact_details.content"
						/>
					</v-expansion-panel-content>
				</v-expansion-panel>

				<!-- Association contact details -->
				<v-expansion-panel
					v-if="(user._association_contact_details && Object.keys(user._association_contact_details).length)"
					color="primary"
					class="mt-3"
				>
					<v-expansion-panel-header color="primary white--text">
						{{user._association_contact_details.title}}

						<template v-slot:actions>
							<v-icon color="white">
								mdi-chevron-down
							</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content
						class="pt-6"
					>
						<dl class="defList">
							<div
								v-if="user._association_contact_details.chairman"
								class="defList__item"
							>
								<dt class="defList__label">{{$t('departmentDetails.chairman')}}</dt>
								<dd class="defList__value">
									<span v-if="user._association_contact_details.chairman.name">
										{{user._association_contact_details.chairman.name}}<br>
									</span>
									<span v-if="user._association_contact_details.chairman.tel">
										{{user._association_contact_details.chairman.tel}}<br>
									</span>
									<span v-if="user._association_contact_details.chairman.email">
										<a :href="'mailto:' + user._association_contact_details.chairman.email">
											{{user._association_contact_details.chairman.email}}
										</a><br>
									</span>
								</dd>
							</div>
							<div
								v-if="user._association_contact_details.secretary"
								class="defList__item"
							>
								<dt class="defList__label">{{$t('departmentDetails.secretary')}}</dt>
								<dd class="defList__value">
									{{user._association_contact_details.secretary.name}}
								</dd>
							</div>
							<div
								v-if="user._association_contact_details.financialManager"
								class="defList__item"
							>
								<dt class="defList__label">{{$t('departmentDetails.financialManager')}}</dt>
								<dd class="defList__value">
									{{user._association_contact_details.financialManager.name}}
								</dd>
							</div>
							<div
								v-if="user._association_contact_details.address || user._association_contact_details.postal || user._association_contact_details.city"
								class="defList__item"
							>
								<dt class="defList__label">{{$t('departmentDetails.officeAddress')}}</dt>
								<dd class="defList__value">
									<span v-if="user._association_contact_details.address">
										{{user._association_contact_details.address}}<br>
									</span>
									<span v-if="user._association_contact_details.postal">
										{{user._association_contact_details.postal}}
									</span>
									<span v-if="user._association_contact_details.city">
										{{user._association_contact_details.city}}
									</span>
								</dd>
							</div>
							<div
								v-if="user._association_contact_details.tel"
								class="defList__item"
							>
								<dt class="defList__label">{{$t('departmentDetails.telephone')}}</dt>
								<dd class="defList__value">
									{{user._association_contact_details.tel}}
								</dd>
							</div>
						</dl>

						<v-btn
							v-if="user._association_contact_details.link"
							text
							color="primary"
							:href="user._association_contact_details.link"
							target="_blank"
						>
							<v-icon left>
								mdi-home
							</v-icon>
							{{$t('general.website')}}
						</v-btn>
					</v-expansion-panel-content>
				</v-expansion-panel>

				<!-- Collective agreement details -->
				<template v-if="(user._collective_agreement && Object.keys(user._collective_agreement).length)">
					<!-- Collective agreement -->
					<v-expansion-panel
						v-if="user._collective_agreement.files && user._collective_agreement.files.length"
						color="primary"
						class="mt-3"
					>
						<v-expansion-panel-header color="primary white--text">
							{{$t('collectiveAgreement.title')}}

							<template v-slot:actions>
								<v-icon color="white">
									mdi-chevron-down
								</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content
							class="pt-6"
						>
							<FilterableList
								:items="user._collective_agreement.files"
								:enableSearch="(false)"
								:multiline="(true)"
							/>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!-- Pay raise -->
					<v-expansion-panel
						v-if="user._collective_agreement.pay_raise_details"
						color="primary"
						class="mt-3"
					>
						<v-expansion-panel-header color="primary white--text">
							{{$t('payRaise.title')}}

							<template v-slot:actions>
								<v-icon color="white">
									mdi-chevron-down
								</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content
							class="pt-6"
						>
							<RichText
								:content="user._collective_agreement.pay_raise_details"
							/>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!-- Daily allowance -->
					<v-expansion-panel
						v-if="user._collective_agreement.daily_allowance_details"
						color="primary"
						class="mt-3"
					>
						<v-expansion-panel-header color="primary white--text">
							{{$t('dailyAllowance.title')}}

							<template v-slot:actions>
								<v-icon color="white">
									mdi-chevron-down
								</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content
							class="pt-6"
						>
							<RichText
								:content="user._collective_agreement.daily_allowance_details"
							/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</template>
			</v-expansion-panels>
		</v-container>

		<!-- Benefit cards -->
		<v-container v-if="benefits.length" class="pt-6">
			<v-row>
				<v-col
					v-for="item in benefits"
					:key="item.id"
					cols="12"
					sm="6"
				>
					<v-card>
						<v-row>
							<v-col cols="8" class="pb-0">
								<v-card-title
									v-if="item.title"
									class="pt-0"
								>
									{{item.title}}
								</v-card-title>
							</v-col>
							<v-col cols="4">
								<div class="pt-1 pr-3">
									<v-img
										v-if="item.logo"
										:src="item.logo.url"
										contain
									/>
								</div>
							</v-col>
						</v-row>

						<v-card-text class="pt-0">
							{{item.summary}}
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'
import RichText from '@/components/RichText'

export default {
	name: 'membershipCard',
	components: {
		FilterableList,
		RichText,
	},
	computed: {
		...mapState([
			'user',
			'benefits',
		]),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
