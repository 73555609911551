<template>
	<div 
		v-if="content.length"
		v-html="content"
		class="copy"
		ref="wrap"
	/>
</template>

<script>

export default {
	name: 'RichText',
	props: {
		content: {
			type: [String],
			required: false,
			default () {
				return ''
			},
		},
	},
	methods: {
		init () {
			if (typeof this.$refs.wrap == 'undefined') return

			// Force links to open in new window
			const links = this.$refs.wrap.querySelectorAll('a')

			// Button styles for links with .button class
			for (const item of links) {
				if (item.classList.contains('button')) {
					item.classList.add(
						'my-5',
						'v-btn',
						'v-btn--block',
						'v-btn--is-elevated',
						'v-btn--has-bg',
						'theme--light',
						'v-size--large',
						'primary'
					)
				}

				const href = item.getAttribute('href')

				if (!href) continue
				if (href.startsWith('tel:') || href.startsWith('mailto:') || href.startsWith('#') || href.startsWith('/app')) continue

				item.setAttribute('target', '_blank')
			}
		}
	},
	mounted () {
		this.init()
	},
	updated () {
		this.init()
	}
}
</script>

<style>
.copy .button {
	white-space: normal;
	height: auto !important;
	padding-top: .5rem !important;
	padding-bottom: .5rem !important;
}
</style>
