<template>
	<v-list-item
		v-if="subtitle"
		class="px-0"
	>
		<v-list-item-content>
			<v-list-item-title>
				{{ title }}
			</v-list-item-title>
			<v-list-item-subtitle
				class="break-line"
			>
				<ul
					v-if="subtitle instanceof Array"
					class="pl-4"
				>
					<li
						v-for="item in subtitle"
						:key="item"
					>
						{{ item }}
					</li>
				</ul>
				<template
					v-else
				>
					{{ subtitle }}
				</template>
			</v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	name: 'SimpleListItem',
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: [String, Array],
			default: '',
		}
	}
}
</script>

<style scoped>
.break-line {
	white-space: pre-line;	
}
</style>