export default {
	general: {
		save: 'Tallenna',
		cancel: 'Peruuta',
		close: 'Sulje',
		yes: 'Kyllä',
		no: 'Ei',
		website: 'Kotisivu',
	},
	routes: {
		error404: {
			title: 'Sivua ei löytynyt',
		},
		membershipCard: {
			title: 'Jäsenkortti',
		},
		myAccount: {
			title: 'Omat tiedot',
			headline: 'Omat tiedot',
		},
		benefitContainer: {
			title: 'Jäsenedut',
			headline: 'Jäsenedut',
		},
		benefit: {
			title: 'Jäsenetu',
			headline: 'Jäsenetu',
		},
		eventContainer: {
			title: 'Tapahtumat',
			headline: 'Tapahtumat',
		},
		event: {
			title: 'Tapahtuma',
			headline: 'Tapahtuma',
		},
		newsContainer: {
			title: 'Uutiset',
			headline: 'Uutiset',
		},
		news: {
			title: 'Uutinen',
			headline: 'Uutinen',
		},
		courseContainer: {
			title: 'Kurssihistoria',
			headline: 'Kurssihistoria',
		},
		resorts: {
			title: 'Lomapaikat',
			headline: 'Lomapaikat',
		},
		resortsForm: {
			title: 'Lomapaikkahakemus',
			headline: 'Lomapaikkahakemus',
		},
		resortApplications: {
			title: 'Lomapaikkahakemukset',
			headline: 'Lomapaikkahakemukset',
		},
		resortReservations: {
			title: 'Varaukset',
			headline: 'Varaukset',
		},
		pageContainer: {
			title: 'AKT',
			headline: 'AKT',
		},
		page: {
			title: 'Sivu',
			headline: 'Sivu',
		},
		settings: {
			title: 'Asetukset',
			headline: 'Asetukset',
		},
	},
}