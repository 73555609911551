<template>
  <v-container class="py-12">
    <template v-if="loading">
      Ladataan...
    </template>
    <template v-else-if="error">
      <p>{{error}}</p>
    </template>
    <template v-else>
      <h1
        v-if="item.title"
        class="headline mb-5"
      >
        {{item.title}}
      </h1>
      <p
        v-if="item.summary"
        class="subtitle-1 mb-6"
      >
        {{item.summary}}
      </p>
      <div v-if="item.content" v-html="item.content"></div>
    </template>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Event',
  data: () => ({
    loading: true,
    error: '',
    item: {}
  }),
  computed: {
    ...mapState([
      'events',
    ])
  },
  mounted () {
    try {
      // Find news item from the store
      this.item = this.events.find(item => {
        return item.name == this.$route.params.pagename
      })

			if (!this.item) throw 'Tapahtumaa ei löytynyt!'

      this.loading = false;
    } catch (error) {
      this.error = error
      this.loading = false;
    }
  },
};
</script>
