<i18n>
{
  "en": {
		"noItemsMessage": "No news to show.",
		"ePaperLinkText": "Read AKT e-paper"
	},
  "fi": {
		"noItemsMessage": "Uutisia ei löytynyt.",
		"ePaperLinkText": "Siirry AKT:n verkkolehteen"
	}
}
</i18n>

<template>
  <div>
    <!-- Output child page -->
    <router-view />

    <!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'newsContainer'">
			<v-container class="pt-10">
				<v-btn
					color="secondary"
					block
					large
					rounded
					href="https://www.aktlehti.fi"
					target="_blank"
				>
					<v-icon left>mdi-book-open-page-variant</v-icon>
					{{$i18n.t('ePaperLinkText')}}
				</v-btn>
			</v-container>

			<FilterableList
				v-if="items.length"
				:items="items"
				@itemClick="itemClick"
			/>
			<v-alert
				v-else
				type="info"
				class="ma-3"
			>
				{{$i18n.t('noItemsMessage')}}
			</v-alert>
		</template>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'NewsContainer',
	components: {
		FilterableList,
	},
  computed: {
		...mapState({
      items: state => state.news,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'news', params: { pagename: item.name } })
		}
	},
  mounted () {
    this.$api.News.doRequest()
  }
};
</script>
