<i18n>
{
  "en": {
		"pendingItemsInfo": "If you'd like to cancel a pending item, open it from the list below and click the \"Cancel application\" button.",
		"noItemsMessage": "No reservations to show.",
		"noPendingItemsMessage": "No applications to show.",
		"dialogTitlePending": "Application",
		"dialogTitle": "Application",
		"cancelApplication": "Cancel application",
		"submitApplication": "Submit new application",
		"canceled": "canceled",
		"labels": {
			"resort": "Confirmed resort",
			"selectedResorts": "Selected resorts",
			"preferredResort": "Preferred resort",
			"applicationPeriod": "Application period",
			"startDate": "Start date",
			"endDate": "End date",
			"preferredStartDate": "Preferred start date",
			"alternativeStartDate": "Alternative start date",
			"otherPossibleDates": "Other possible dates",
			"firstTimeVisitor": "Are you a first time visitor?",
			"takingPet": "Taking a pet with me"
		},
		"confirm": {
			"title": "Confirm cancellation",
			"text": "Are you sure you want to cancel your application for application period __APPLICATION_PERIOD__?"
		}
	},
  "fi": {
		"pendingItemsInfo": "Jos haluat perua hakemuksen, valitse se alla näkyvästä listasta ja klikkaa \"Peruuta hakemus\" -painiketta.",
		"noItemsMessage": "Varauksia ei löytynyt.",
		"noPendingItemsMessage": "Hakemuksia ei löytynyt.",
		"dialogTitlePending": "Hakemus",
		"dialogTitle": "Varaus",
		"cancelApplication": "Peruuta hakemus",
		"submitApplication": "Lähetä uusi hakemus",
		"canceled": "peruutettu",
		"labels": {
			"resort": "Vahvistettu lomakohde",
			"selectedResorts": "Haetut lomakohteet",
			"preferredResort": "Ensisijainen lomakohde",
			"applicationPeriod": "Lomapaikan hakuaika",
			"startDate": "Alkaa",
			"endDate": "Päättyy",
			"preferredStartDate": "Ensisijainen aloitusaika",
			"alternativeStartDate": "Vaihtoehtoinen aloitusaika",
			"otherPossibleDates": "Muut ajankohtatoiveet",
			"firstTimeVisitor": "Oletko ensikertalainen?",
			"takingPet": "Lemmikkieläin mukana"
		},
		"confirm": {
			"title": "Vahvista peruutus",
			"text": "Haluatko varmasti peruuttaa hakemuksesi hakuajalle __APPLICATION_PERIOD__?"
		}
	}
}
</i18n>

<template>
	<div class="pt-3">

		<v-alert
			v-if="showPending && filteredItems.length"
			type="info"
			class="ma-3"
		>
			{{ $i18n.t('pendingItemsInfo') }}
		</v-alert>

		<FilterableList
			v-if="filteredItems.length"
			:items="filteredItems"
			subtitleSrc="startDate"
			summarySrc="location"
			:enableSearch="!showPending"
			@itemClick="openDialog"
		>
			<template v-slot:content="{ item }">

				<v-list-item-title
					class="v-list-item__title--multiline"
				>
					<template
						v-if="item.resort || item.preferredResort"
					>
						<template
							v-if="item.startDate || item.preferredStartDate"
						>
							{{ item.startDate || item.preferredStartDate }}
							<template
								v-if="item.startDate && item.endDate && item.endDate !== item.startDate"
							>
								- {{ item.endDate }}
							</template>
							<template
								v-else-if="!item.startDate"
							>
								→
							</template>
						</template>
					</template>
					<template
						v-else
					>
						{{ item.title }}
					</template>
					<template
						v-if="item.canceled"
					>
						({{ $i18n.t('canceled') }})
					</template>
				</v-list-item-title>

				<v-list-item-subtitle
					v-if="item.resort || item.preferredResort"
					class="v-list-item__subtitle--multiline"
				>
					{{ item.resort || item.preferredResort }}
				</v-list-item-subtitle>

			</template>
		</FilterableList>
		<v-alert
			v-else
			type="info"
			class="ma-3"
		>
			<template
				v-if="showPending"
			>
				{{ $i18n.t('noPendingItemsMessage') }}
				<v-btn
					color="primary"
					class="mt-5"
					:to="({ name: 'resortsForm' })"
				>
					{{ $i18n.t('submitApplication') }}
				</v-btn>
			</template>
			<template
				v-else
			>
				{{ $i18n.t('noItemsMessage') }}
			</template>
		</v-alert>

		<!-- application dialog -->
		<v-dialog
			v-model="dialogOpen"
			max-width="500"
			persistent
			scrollable
		>
			<v-card>
				<v-card-title>
					{{ openItem.resort ? $i18n.t('dialogTitle') : $i18n.t('dialogTitlePending') }}
				</v-card-title>
				<v-card-text>

					<SimpleListItem
						:title="$i18n.t('labels.applicationPeriod')"
						:subtitle="openItem.applicationPeriod"
					></SimpleListItem>

					<template
						v-if="openItem.resort"
					>
						<SimpleListItem
							:title="$i18n.t('labels.resort')"
							:subtitle="openItem.resort"
						></SimpleListItem>
						<SimpleListItem
							:title="$i18n.t('labels.startDate')"
							:subtitle="openItem.startDate"
						></SimpleListItem>
						<SimpleListItem
							:title="$i18n.t('labels.endDate')"
							:subtitle="openItem.endDate"
						></SimpleListItem>
					</template>

					<template
						v-else
					>
						<SimpleListItem
							:title="$i18n.t('labels.selectedResorts')"
							:subtitle="openItem.selectedResorts"
						></SimpleListItem>
						<SimpleListItem
							:title="$i18n.t('labels.preferredResort')"
							:subtitle="openItem.preferredResort"
						></SimpleListItem>
						<SimpleListItem
							:title="$i18n.t('labels.preferredStartDate')"
							:subtitle="openItem.preferredStartDate"
						></SimpleListItem>
						<SimpleListItem
							:title="$i18n.t('labels.alternativeStartDate')"
							:subtitle="openItem.alternativeStartDate"
						></SimpleListItem>
						<SimpleListItem
							:title="$i18n.t('labels.otherPossibleDates')"
							:subtitle="openItem.otherPossibleDates"
						></SimpleListItem>
					</template>

					<SimpleListItem
						:title="$i18n.t('labels.firstTimeVisitor')"
						:subtitle="openItem.firstTimeVisitor"
					></SimpleListItem>
					<SimpleListItem
						:title="$i18n.t('labels.takingPet')"
						:subtitle="openItem.takingPet"
					></SimpleListItem>

					<v-btn
						v-if="openItem.id && !openItem.resort"
						color="error"
						class="mt-6"
						@click="confirmSave"
					>
						<v-icon
							left
						>
							mdi-calendar-remove
						</v-icon>
						{{ $i18n.t('cancelApplication') }}
					</v-btn>

				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						color="primary"
						text
						@click="close"
					>
						{{ $i18n.t('general.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- removal confirmation dialog -->
		<v-dialog
			v-model="confirmDialogOpen"
			max-width="500"
			persistent
			scrollable
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('confirm.title') }}
				</v-card-title>
				<v-card-text>
					{{ $i18n.t('confirm.text').replace('__APPLICATION_PERIOD__', openItem.applicationPeriod) }}
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="error"
						v-if="!openItem.resort"
						:disabled="saveLoading"
						@click="save"
					>
						<v-icon
							left
						>
							mdi-calendar-remove
						</v-icon>
						{{ $i18n.t('cancelApplication') }}
					</v-btn>
					<v-spacer />
					<v-btn
						color="primary"
						text
						:disabled="saveLoading"
						:loading="saveLoading"
						@click="close"
					>
						{{ $i18n.t('general.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import FilterableList from '../components/FilterableList.vue'
import SimpleListItem from '../components/SimpleListItem.vue'

export default {
	name: 'ResortApplications',
	components: {
		FilterableList,
		SimpleListItem
	},
	props: {
		showPending: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		searchQuery: '',
		openItem: {},
		dialogOpen: false,
		confirmDialogOpen: false,
		saveLoading: false,
	}),
	computed: {
		...mapState({
			items: state => state.resortApplications,
		}),
		filteredItems() {
			return this.items.filter(item => this.showPending ? !item.resort : !!item.resort)
		},
	},
	methods: {
		openDialog (item) {
			this.openItem = Object.assign({}, item)
			this.dialogOpen = true
		},

		close () {
			this.dialogOpen = false
			this.confirmDialogOpen = false
			this.openItem = {}
		},

		confirmSave() {
			this.dialogOpen = false
			this.confirmDialogOpen = true
		},

		save () {
			this.saveLoading = true

			this.$api.ResortApplications.doRequest({
				method: 'POST',
				body: this.openItem,
			}).on('done', (res) => {

				// Reset dialog and open item
				this.close()

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
	mounted () {
		this.$api.ResortApplications.doRequest()
	},
};
</script>
