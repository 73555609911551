<i18n>
{
  "en": {
		"noItemsMessage": "No events to show."
	},
  "fi": {
		"noItemsMessage": "Tapahtumia ei löytynyt."
	}
}
</i18n>

<template>
  <div>
    <!-- Output child page -->
    <router-view />

    <!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'eventContainer'">
			<FilterableList
				v-if="items.length"
				:items="items"
				summarySrc="dateFormatted"
				redirectUrlSrc="url"
				@itemClick="itemClick"
			/>
			<v-alert
				v-else
				type="info"
				class="ma-3"
			>
				{{$i18n.t('noItemsMessage')}}
			</v-alert>
		</template>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'EventContainer',
	components: {
		FilterableList,
	},
  computed: {
    ...mapState({
      items: state => state.events,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'event', params: { pagename: item.name } })
		}
	},
	mounted () {
    this.$api.Events.doRequest()
  },
};
</script>
