import Vue from 'vue'
import goTo from 'vuetify/lib/services/goto'
import store from '@/store'
import VueRouter from 'vue-router'
import Error404 from '@/views/Error404.vue'
import MembershipCard from '@/views/MembershipCard.vue'
import Settings from '@/views/Settings.vue'
import BenefitContainer from '@/views/BenefitContainer.vue'
import Benefit from '@/views/Benefit.vue'
import EventContainer from '@/views/EventContainer.vue'
import Event from '@/views/Event.vue'
import NewsContainer from '@/views/NewsContainer.vue'
import News from '@/views/News.vue'
import CourseContainer from '@/views/CourseContainer.vue'
import Resorts from '@/views/Resorts.vue'
import ResortsForm from '@/views/ResortsForm.vue'
import ResortApplications from '@/views/ResortApplications.vue'
import PageContainer from '@/views/PageContainer.vue'
import Page from '@/views/Page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
		name: "membershipCard",
    component: MembershipCard,
    meta: {
      icon: "mdi-badge-account-horizontal"
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      icon: "mdi-cog",
      hidden: true
    }
  },
  {
    path: "/benefits",
    name: "benefitContainer",
    component: BenefitContainer,
    meta: {
			icon: "mdi-star",
			hidden: true,
    },
    children: [
      {
        path: ":pagename",
        name: "benefit",
        component: Benefit,
        props: true,
      }
    ]
  },
  {
    path: "/events",
    name: "eventContainer",
    component: EventContainer,
    meta: {
      icon: "mdi-calendar"
    },
    children: [
      {
        path: ":pagename",
        name: "event",
        component: Event,
        props: true,
      }
    ]
  },
  {
    path: "/news",
    name: "newsContainer",
    component: NewsContainer,
    meta: {
      icon: "mdi-bullhorn"
    },
    children: [
      {
        path: ":pagename",
        name: "news",
        component: News,
        props: true,
      }
    ]
  },
  {
    path: "/courses",
    name: "courseContainer",
    component: CourseContainer,
    meta: {
      icon: "mdi-school",
      hidden: true
    }
  },
  {
    path: "/resorts",
    name: "resorts",
    component: Resorts,
    meta: {
      icon: "mdi-home-group",
      hidden: true
    },
	children: [
		{
			path: "/resorts/form",
			name: "resortsForm",
			component: ResortsForm
		},
		{
			path: "/resorts/applications",
			name: "resortApplications",
			component: ResortApplications,
			props: {
				showPending: true
			}
		},
		{
			path: "/resorts/reservations",
			name: "resortReservations",
			component: ResortApplications,
			props: {
				showPending: false
			}
		},
	]
  },
  {
    path: "/pages",
    name: "pageContainer",
    component: PageContainer,
    meta: {
      icon: "mdi-file-document-multiple"
    },
    children: [
      {
        path: ":pagename",
        name: "page",
        component: Page,
        props: true,
      }
    ]
  },
  {
    name: "Error404",
    path: "/error404",
    component: Error404,
    meta: {
      hidden: true
    }
  },
  {
    path: "*",
    redirect: {
      name: "Error404"
    },
    meta: {
      hidden: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo, {
			duration: 0,
		})
  },
	routes,
})

// Navigation guards
router.beforeEach((to, from, next) => {
	// If current route is not the first one the user visits,
	// we switch back button to the history mode.
	if (from.name !== null) {
		store.commit('setBackButtonHistoryMode', true)
	}

	// If navigation confirmation is requested, show dialog
	if (store.state.confirmNavigation) {
		store.commit('setConfirmNavigationDialog', true)
		store.commit('setConfirmNavigationRoute', to)
	} else {
		next()
	}
})

export default router
