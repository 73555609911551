var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"salaryCalculator mt-6"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-form',{attrs:{"autocomplete":"off"},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-radio-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.collectiveAgreement'))+" ")])]},proxy:true}]),model:{value:(_vm.formData.collectiveAgreement),callback:function ($$v) {_vm.$set(_vm.formData, "collectiveAgreement", $$v)},expression:"formData.collectiveAgreement"}},[_c('v-radio',{attrs:{"label":_vm.$t('labels.tes_kuormaAutoAla'),"value":"tes_kuormaAutoAla"}}),_c('v-radio',{attrs:{"label":_vm.$t('labels.tes_sailioautoAla'),"value":"tes_sailioautoAla"}})],1),_c('v-text-field',{staticClass:"v-input--required",attrs:{"type":"number","label":_vm.$t('labels.hourlyWage'),"rules":[
						_vm.validationRules.required,
						_vm.validationRules.sum ],"append-outer-icon":"mdi-currency-eur"},model:{value:(_vm.formData.hourlyWage),callback:function ($$v) {_vm.$set(_vm.formData, "hourlyWage", $$v)},expression:"formData.hourlyWage"}}),_c('v-text-field',{staticClass:"v-input--required",attrs:{"type":"number","label":_vm.$t('labels.hours'),"rules":[
						_vm.validationRules.required,
						_vm.validationRules.sum ]},model:{value:(_vm.formData.hours),callback:function ($$v) {_vm.$set(_vm.formData, "hours", $$v)},expression:"formData.hours"}}),_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('labels.holidayHours'),"rules":[
						_vm.validationRules.sum ]},model:{value:(_vm.formData.holidayHours),callback:function ($$v) {_vm.$set(_vm.formData, "holidayHours", $$v)},expression:"formData.holidayHours"}}),_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('labels.eveningHours') + ' (18–22)',"rules":[
						_vm.validationRules.sum ]},model:{value:(_vm.formData.eveningHours),callback:function ($$v) {_vm.$set(_vm.formData, "eveningHours", $$v)},expression:"formData.eveningHours"}}),_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('labels.nightHours') + ' (22–06)',"rules":[
						_vm.validationRules.sum ]},model:{value:(_vm.formData.nightHours),callback:function ($$v) {_vm.$set(_vm.formData, "nightHours", $$v)},expression:"formData.nightHours"}})],1)],1),(_vm.results.rows && _vm.results.rows.length)?[_c('v-divider'),_c('v-card-text',[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('resultsTable.heading'))+" ")]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-right no-line-break"},[_vm._v(_vm._s(_vm.$t('resultsTable.hours')))]),_c('th',{staticClass:"text-right no-line-break"},[_vm._v("€ / h")]),_c('th',{staticClass:"text-right no-line-break"},[_vm._v(_vm._s(_vm.$t('resultsTable.total')))])])]),_c('tbody',_vm._l((_vm.results.rows),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.title))]),_c('td',{staticClass:"text-right no-line-break"},[_vm._v(_vm._s(_vm.formatSum(item.hours, 'h')))]),_c('td',{staticClass:"text-right no-line-break"},[_vm._v(_vm._s(_vm.formatSum(item.hourlyWage, '€')))]),_c('td',{staticClass:"text-right no-line-break"},[_vm._v(_vm._s(_vm.formatSum(item.total, '€')))])])}),0),_c('tfoot',[_c('tr',[_c('th',{staticClass:"text-right",attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.$t('resultsTable.total')))]),_c('td',{staticClass:"font-weight-bold text-right no-line-break"},[_vm._v(_vm._s(_vm.formatSum(_vm.results.totals.hours, 'h')))]),_c('td',{staticClass:"font-weight-bold text-right no-line-break"}),_c('td',{staticClass:"font-weight-bold text-right no-line-break"},[_vm._v(_vm._s(_vm.formatSum(_vm.results.totals.total, '€')))])])])])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }