<template>
	<div>
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				height="200"
			/>
		</v-container>
		<v-container class="py-6">
			<template v-if="loading">
				Ladataan...
			</template>
			<template v-else-if="error">
				<p>{{error}}</p>
			</template>
			<template v-else>
				<h1
					v-if="item.title"
					class="headline mb-5"
				>
					{{item.title}}
				</h1>
				<p
					v-if="item.summary"
					class="subtitle-1 mb-6"
				>
					{{item.summary}}
				</p>
				<div v-if="item.content" v-html="item.content"></div>
			</template>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'News',
  data: () => ({
    loading: true,
    error: '',
    item: {}
  }),
  computed: {
    ...mapState([
      'benefits',
    ])
  },
  mounted () {
    try {
      // Find news item from the store
      this.item = this.benefits.find(item => {
        return item.name == this.$route.params.pagename
      })

      if (!this.item) throw 'Jäsenetua ei löytynyt!'

      this.loading = false;
    } catch (error) {
      this.error = error
      this.loading = false;
    }
  },
};
</script>
