import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		error: {},
		config: {
			social_media_links: [],
		},
		settings: {
			locale: 'fi', // Default language
			darkMode: false,
		},
		user: {},
		news: [],
		events: [],
		benefits: [],
		courses: [],
		resortsData: [],
		resortApplications: [],
		pages: [],
		notifyMessage: '',
		notifyMessageVisible: false,
		notifyError: '',
		notifyErrorVisible: false,
		updateAvailable: false,
		confirmNavigation: false,
		confirmNavigationRoute: {},
		confirmNavigationDialog: false,
	},
	mutations: {
		setError(state, payload) {
			state.error = Object.assign({
				'title': '',
				'summary': '',
				'buttonTitle': '',
				'buttonHref': '/app/',
			}, payload)
		},

		clearError(state) {
			state.error = {}
		},

		setConfig(state, payload) {
			state.config = Object.assign({}, payload)
		},

		setSettings(state, payload) {
			state.settings = Object.assign({}, payload)
		},

		setUser(state, payload) {
			state.user = payload
		},

		setNews(state, payload) {
			state.news = payload
		},

		setEvents(state, payload) {
			state.events = payload
		},

		setBenefits(state, payload) {
			state.benefits = payload
		},

		setCourses(state, payload) {
			state.courses = payload
		},

		setResortsData(state, payload) {
			state.resortsData = payload
		},

		setResortApplications(state, payload) {
			state.resortApplications = payload
		},

		setPages(state, payload) {
			state.pages = payload
		},

		setNotifyMessage(state, payload) {
			state.notifyMessage = payload
		},

		setNotifyMessageVisible(state, payload) {
			state.notifyMessageVisible = payload
		},

		setNotifyError(state, payload) {
			state.notifyError = payload
		},

		setNotifyErrorVisible(state, payload) {
			state.notifyErrorVisible = payload
		},

		setUpdateAvailable (state, payload) {
			state.updateAvailable = payload
		},

		setConfirmNavigation(state, payload) {
			state.confirmNavigation = payload
		},

		setConfirmNavigationRoute(state, payload) {
			state.confirmNavigationRoute = payload
		},

		setConfirmNavigationDialog(state, payload) {
			state.confirmNavigationDialog = payload
		},

		setBackButtonHistoryMode(state, payload) {
			state.backButtonHistoryMode = payload
		},
	},
	actions: {
		clearData({ commit }) {
			return new Promise(resolve => {
				commit('clearError')
				commit('setConfig', {})
				commit('setUser', {})
				commit('setNews', [])
				commit('setEvents', [])
				commit('setBenefits', [])
				commit('setCourses', [])
				commit('setResortsData', [])
				commit('setResortApplications', [])
				commit('setPages', [])
				commit('setConfirmNavigation', false)
				commit('setConfirmNavigationDialog', false)
				commit('setConfirmNavigationRoute', {})
				commit('setBackButtonHistoryMode', false)

				resolve()
			})
		},

		getSettings({ state, commit }) {
			return new Promise(resolve => {
				// Get settings from localstorage
				let settings = localStorage.getItem('settings') || '{}'
				settings = JSON.parse(settings)

				// Merge user settings with current settings
				settings = Object.assign(state.settings, settings)

				commit('setSettings', settings)

				resolve(settings)
			})
		},

		setSettings({ commit }, payload) {
			commit('setSettings', payload)

			// Save to localstorage
			localStorage.setItem('settings', JSON.stringify(payload))
		},

		clearConfirmNavigation({ commit }) {
			commit('setConfirmNavigation', false)
			commit('setConfirmNavigationDialog', false)
			commit('setConfirmNavigationRoute', {})
		},

		setNotifyMessage({ commit }, payload) {
			commit('setNotifyMessageVisible', true)
			commit('setNotifyMessage', payload)
		},

		setNotifyError({ commit }, payload) {
			commit('setNotifyErrorVisible', true)
			commit('setNotifyError', payload)
		},
	}
})
