import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Touch } from "vuetify/lib/directives";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  directives: {
    Touch
  }
})

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#174579",
        secondary: "#00B0FF",
        accent: "#174579",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      },
      dark: {
        primary: "#56a5ff",
        secondary: "#00B0FF",
        accent: "#56a5ff",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
});
