<template>
	<v-app-bar
		class="topBar"
		app
		scroll-off-screen
	>
		<v-btn
			v-show="showBackButton"
			icon
			@click="goBack"
		>
			<v-icon>mdi-arrow-left</v-icon>
		</v-btn>
		<v-spacer></v-spacer>

		<v-btn
			class="logo"
			icon
			@click="gotoHomeRoute"
		>
			<v-img
				height="30"
				src="@/assets/logo.svg"
				contain
			/>
		</v-btn>
		<v-spacer></v-spacer>
		<v-btn
			icon
			@click="$emit('menu-button-click')"
		>
			<v-icon>mdi-menu</v-icon>
		</v-btn>
		<v-toolbar-title
			v-if="$i18n.te('routes.' + $route.name + '.headline')"
			slot="extension"
		>
			{{$i18n.t('routes.' + $route.name + '.headline')}}
		</v-toolbar-title>
	</v-app-bar>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'TopBar',
	computed: {
		...mapState({
			backButtonHistoryMode: state => state.backButtonHistoryMode,
		}),
		showBackButton () {
			return this.$route.matched.length >= 2
		}
	},
	methods: {
		gotoHomeRoute () {
			const homeRoute = this.$router.options.routes.find(item => item.name == 'membershipCard')

			if (!homeRoute || this.$route.name == homeRoute.name) return

			this.$router.push({
				name: homeRoute.name,
			})
		},
		goBack () {
			if (this.backButtonHistoryMode === true) {
				history.back()
			} else {
				const parentRoute = this.$route.matched[this.$route.matched.length - 2]

				this.$router.replace(parentRoute)
			}
		}
	}
}
</script>

<style lang="scss" scoped>

	.logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

</style>