<i18n>
{
  "en": {
		"title": null,
		"labels": {
			"collectiveAgreement": "Collective agreement",
			"tes_kuormaAutoAla": null,
			"tes_sailioautoAla": null,
			"hourlyWage": null,
			"hours": null,
			"holidayHours": null,
			"eveningHours": null,
			"nightHours": null
		},
		"resultsTable": {
			"heading": null,
			"hours": null,
			"total": null
		}
	},
	"fi": {
		"title": "Palkkalaskuri",
    "labels": {
			"collectiveAgreement": "Työehtosopimus",
			"tes_kuormaAutoAla": "Kuorma-autoalan työehtosopimus",
			"tes_sailioautoAla": "Säiliöauto- ja öljytuotealan työehtosopimus",
			"hourlyWage": "Tuntipalkka",
			"hours": "Tehdyt työtunnit",
			"holidayHours": "Pyhätyötunnit",
			"eveningHours": "Iltatyötunnit",
			"nightHours": "Yötyötunnit"
		},
		"resultsTable": {
			"heading": "Laskelma",
			"hours": "Tunnit",
			"total": "Yhteensä"
		}
	}
}

</i18n>

<template>
	<v-card class="salaryCalculator mt-6">
		<v-card-title>
			{{$t('title')}}
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-form
				v-model="formValid"
				autocomplete="off"
			>
				<v-radio-group
					v-model="formData.collectiveAgreement"
				>
					<template v-slot:label>
						<p class="font-weight-bold mb-0">
							{{ $t('labels.collectiveAgreement') }}
						</p>
					</template>
					<v-radio
						:label="$t('labels.tes_kuormaAutoAla')"
						value="tes_kuormaAutoAla"
					/>
					<v-radio
						:label="$t('labels.tes_sailioautoAla')"
						value="tes_sailioautoAla"
					/>
				</v-radio-group>
					<v-text-field
						v-model="formData.hourlyWage"
						type="number"
						class="v-input--required"
						:label="$t('labels.hourlyWage')"
						:rules="[
							validationRules.required,
							validationRules.sum,
						]"
						append-outer-icon="mdi-currency-eur"
					/>
					<v-text-field
						v-model="formData.hours"
						type="number"
						class="v-input--required"
						:label="$t('labels.hours')"
						:rules="[
							validationRules.required,
							validationRules.sum,
						]"
					/>
					<v-text-field
						v-model="formData.holidayHours"
						type="number"
						:label="$t('labels.holidayHours')"
						:rules="[
							validationRules.sum,
						]"
					/>
					<v-text-field
						v-model="formData.eveningHours"
						type="number"
						:label="$t('labels.eveningHours') + ' (18–22)'"
						:rules="[
							validationRules.sum,
						]"
					/>
					<v-text-field
						v-model="formData.nightHours"
						type="number"
						:label="$t('labels.nightHours') + ' (22–06)'"
						:rules="[
							validationRules.sum,
						]"
					/>
			</v-form>
		</v-card-text>

		<!-- Results -->
		<template
			v-if="results.rows && results.rows.length"
		>
			<v-divider />
			<v-card-text>
				<h2>
					{{$t('resultsTable.heading')}}
				</h2>
				<v-simple-table>
					<thead>
						<tr>
							<th></th>
							<th class="text-right no-line-break">{{$t('resultsTable.hours')}}</th>
							<th class="text-right no-line-break">€ / h</th>
							<th class="text-right no-line-break">{{$t('resultsTable.total')}}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(item, index) in results.rows"
							:key="index"
						>
							<td class="text-left">{{item.title}}</td>
							<td class="text-right no-line-break">{{formatSum(item.hours, 'h')}}</td>
							<td class="text-right no-line-break">{{formatSum(item.hourlyWage, '€')}}</td>
							<td class="text-right no-line-break">{{formatSum(item.total, '€')}}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<th scope="row" class="text-right">{{$t('resultsTable.total')}}</th>
							<td class="font-weight-bold text-right no-line-break">{{formatSum(results.totals.hours, 'h')}}</td>
							<td class="font-weight-bold text-right no-line-break"></td>
							<td class="font-weight-bold text-right no-line-break">{{formatSum(results.totals.total, '€')}}</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-card-text>
		</template>
	</v-card>
</template>

<script>

import validationRules from '@/utils/validationRules'

export default {
	name: 'SalaryCalculator',
	data: () => ({
		validationRules: validationRules,
		formValid: false,
		formData: {
			collectiveAgreement: 'tes_kuormaAutoAla',
			hourlyWage: null,
			hours: null,
			holidayHours: null,
			eveningHours: null,
			nightHours: null,
		},
	}),
	computed: {
		results () {
			let results = {
				rows: [],
				totals: {
					hours: 0,
					total: 0,
				},
			}

			if (this.formValid === false) return results

			// Parse form data
			const formData = Object.entries(this.formData).reduce((acc, [key, value]) => {
				// Strings
				if (['collectiveAgreement'].includes(key)) {
					acc[key] = value
				} else {
					// Floats
					acc[key] = parseFloat(value || 0)
				}

				return acc
			}, {})

			// Calculate amount overtime hours
			let overtime50hours = 0
			let overtime100hours = 0

			if (formData.collectiveAgreement === 'tes_kuormaAutoAla') {
				if (formData.hours > 90) {
					overtime50hours = 10
					overtime100hours = formData.hours - 90
				} else if (formData.hours > 80) {
					overtime50hours = formData.hours - 80
					overtime100hours = 0
				} else {
					overtime50hours = 0
					overtime100hours = 0
				}
			} else {
				if (formData.hours > 92) {
					overtime50hours = 12
					overtime100hours = formData.hours - 92
				} else if (formData.hours > 80) {
					overtime50hours = formData.hours - 80
					overtime100hours = 0
				} else {
					overtime50hours = 0
					overtime100hours = 0
				}
			}

			// Standard hours
			if (formData.hours && formData.hours > 0) {
				let hourlyWage = formData.hourlyWage
				let total = formData.hours * hourlyWage

				results.rows.push({
					title: this.$t('labels.hours'),
					hours: formData.hours,
					hourlyWage: hourlyWage,
					total: total,
				})

				results.totals.hours += formData.hours
				results.totals.total += total
			}

			// Overtime 50 % hours
			if (overtime50hours > 0) {
				let hourlyWage = formData.hourlyWage * 0.5
				let total = overtime50hours * hourlyWage

				results.rows.push({
					title: '50 %',
					hours: overtime50hours,
					hourlyWage: hourlyWage,
					total: total,
				})

				results.totals.total += total
			}

			// Overtime 100 % hours
			if (overtime100hours > 0) {
				let hourlyWage = formData.hourlyWage
				let total = overtime100hours * hourlyWage

				results.rows.push({
					title: '100 %',
					hours: overtime100hours,
					hourlyWage: hourlyWage,
					total: total,
				})

				results.totals.total += total
			}

			// Holiday hours
			if (formData.holidayHours && formData.holidayHours > 0) {
				let hourlyWage = formData.hourlyWage
				let total = formData.holidayHours * hourlyWage

				results.rows.push({
					title: this.$t('labels.holidayHours'),
					hours: formData.holidayHours,
					hourlyWage: hourlyWage,
					total: total,
				})

				results.totals.total += total
			}

			// Evening hours
			if (formData.eveningHours && formData.eveningHours > 0) {
				let hourlyWage = formData.hourlyWage * 0.15
				let total = formData.eveningHours * hourlyWage

				results.rows.push({
					title: this.$t('labels.eveningHours'),
					hours: formData.holidayHours,
					hourlyWage: hourlyWage,
					total: total,
				})

				results.totals.total += total
			}

			// Night hours
			if (formData.nightHours && formData.nightHours > 0) {
				let hourlyWage = formData.hourlyWage * 0.2
				let total = formData.nightHours * hourlyWage

				results.rows.push({
					title: this.$t('labels.nightHours'),
					hours: formData.nightHours,
					hourlyWage: hourlyWage,
					total: total,
				})

				results.totals.total += total
			}

			return results
		},
	},

	methods: {
		formatSum (val = null, unit = null) {
			if (!val) return null

			let out = ''

			// Parse amount
			out += Number(Math.round(val + "e2") + "e-2")
				.toFixed(2)
				.toString()
				.replace('.', ',')

			// Add unit
			out += (unit) ? ' ' + unit : ''

			return out
		}
	},
}

</script>

<style lang="scss" scoped>

</style>