<template>
	<div v-if="item">
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				aspect-ratio="1.33"
			/>
		</v-container>
		<v-container class="py-6">
			<h1
				v-if="item.title"
				class="headline mb-5"
			>
				{{item.title}}
			</h1>
			<p
				v-if="item.date"
				class="subtitle-1"
			>
				{{item.date}}
			</p>
			<p
				v-if="item.summary"
				class="subtitle-1 mb-6"
			>
				{{item.summary}}
			</p>
			<RichText
				:content="item.content"
			/>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import RichText from '@/components/RichText'

export default {
  name: 'News',
	components: {
		RichText,
	},
  data: () => ({
    loading: true,
    error: '',
    item: null,
  }),
  computed: {
    ...mapState([
      'news',
    ])
  },
  mounted () {
    // Find current page from the store
    this.item = this.news.find(item => {
      return item.name == this.$route.params.pagename
    })

    if (!this.item) {
      this.$router.replace({ name: 'Error404' })
    }

    this.loading = false
  },
};
</script>
