<i18n>
{
  "en": {
		"headings": {
			"links": "Links",
			"content": "Other content",
			"application": "Application"
		},
		"actions": {
			"logout": "Log out"
		}
	},
	"fi": {
		"headings": {
			"links": "Linkit",
			"content": "Muu sisältö",
			"application": "Sovellus"
		},
		"actions": {
			"logout": "Kirjaudu ulos"
		}
	}
}
</i18n>

<template>
	<v-list
		dense
		flat
	>
		<v-list-item-group
			v-if="config.social_media_links.length"
			color="primary"
		>
			<v-subheader class="text-uppercase">
				{{$i18n.t('headings.links')}}
			</v-subheader>
			<v-list-item
				v-for="(item, index) in config.social_media_links"
				:key="index"
				:href="item.url"
				target="_blank"
			>
				<v-list-item-icon v-if="item.icon && item.custom">
					<template>
						<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.8);" fill="rgba(0,0,0,.54)">
							<path d="m18.901 1.153h3.68l-8.04 9.19 9.459 12.503h-7.406l-5.8-7.584-6.638 7.584h-3.682l8.6-9.83-9.074-11.862h7.594l5.243 6.932zm-1.291 19.491h2.039l-13.163-17.404h-2.188z"/>
						</svg>
					</template>
				</v-list-item-icon>
				<v-list-item-icon v-if="item.icon && !item.custom">
					<v-icon>
						{{item.icon}}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content v-if="item.label">
					{{item.label}}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
		<v-list-item-group
			v-if="user._show_courses || user._show_resorts"
			color="primary"
		>
			<v-subheader class="text-uppercase">
				{{$i18n.t('headings.content')}}
			</v-subheader>
			<v-list-item
				v-if="user._show_courses && $router.options.routes.find(route => route.name == 'courseContainer')"
				:to="({ name: 'courseContainer' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-school
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{$i18n.t('routes.courseContainer.title')}}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="user._show_resorts && $router.options.routes.find(route => route.name == 'resorts')"
				:to="({ name: 'resorts' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-home-group
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{$i18n.t('routes.resorts.title')}}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
		<v-list-item-group
			color="primary"
		>
			<v-subheader class="text-uppercase">
				{{$i18n.t('headings.application')}}
			</v-subheader>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'myAccount')"
				:to="({ name: 'myAccount' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-account
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{$i18n.t('routes.myAccount.title')}}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'settings')"
				:to="({ name: 'settings' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-cog
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{$i18n.t('routes.settings.title')}}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				@click="$emit('logout')"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-logout
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{$i18n.t('actions.logout')}}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'OffCanvasNav',
	computed: {
		...mapState([
			'user',
			'config',
		]),
	}
}

</script>

<style lang="scss" scoped>

</style>